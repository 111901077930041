<template>
  <Loader :visible="loading" fixed/>
  <PhotosAndMeasurements v-if="!loading && preparedResponses"
                         v-model="preparedResponses"
                         ref="PhotosAndMeasurements"/>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PhotosAndMeasurements from '@/components/quiz/PhotosAndMeasurements/PhotosAndMeasurements.vue'
import Loader from '../components/Loader.vue'

export default {
  name: 'Measurements',
  components: {
    PhotosAndMeasurements, Loader
  },
  data () {
    return {
      loading: true,
      preparedResponses: null
    }
  },
  computed: {
    ...mapGetters(['targetSurvey'])
  },
  methods: {
    ...mapActions(['getTargetSurvey']),

    // Prepare the measurements data before passing to child
    // In the parent Measurements.vue component
    prepareMeasurements () {
      // Make sure targetSurvey and its properties exist
      if (!this.targetSurvey || !this.targetSurvey.responses) {
        console.warn('targetSurvey data structure is incomplete')
        return {}
      }

      console.log('resp:', this.targetSurvey.responses)

      // Initialize empty measurements object
      let measurements = {}

      const furnitureToProcess = ['rug', 'sofa', 'sideboard', 'nightstand', 'dresser', 'dining_table']

      // Handle measurements that might be stored as a string
      if (typeof this.targetSurvey.responses.measurements === 'string' &&
         this.targetSurvey.responses.measurements.trim() !== '') {
        try {
          // Try to parse it as JSON
          measurements = JSON.parse(this.targetSurvey.responses.measurements)

          furnitureToProcess.forEach(furniture => {
            if (measurements[furniture] && measurements[furniture].includes('x')) {
              measurements[`${furniture}A`] = measurements[furniture].split('x')[0] || ''
              measurements[`${furniture}B`] = measurements[furniture].split('x')[1] || ''

              delete measurements[furniture]
            }
          })
        } catch (error) {
          console.error('Error parsing measurements string:', error)
          measurements = {}
        }
      } else if (this.targetSurvey.responses.measurements &&
     typeof this.targetSurvey.responses.measurements === 'object') {
        // Use existing object if it's already an object
        measurements = this.targetSurvey.responses.measurements

        furnitureToProcess.forEach(furniture => {
          if (measurements[furniture] && measurements[furniture].includes('x')) {
            measurements[`${furniture}A`] = measurements[furniture].split('x')[0] || ''
            measurements[`${furniture}B`] = measurements[furniture].split('x')[1] || ''
            delete measurements[furniture]
          }
        })
      }

      // Ensure measurements for all furniture items
      if (Array.isArray(this.targetSurvey.responses.furnitures)) {
        this.targetSurvey.responses.furnitures.forEach((furniture) => {
          if (!furnitureToProcess.includes(furniture)) {
            // Use existing value or initialize with empty string
            measurements[furniture] = measurements[furniture] || ''
          }
        })
      }

      // Process floorplan data to ensure correct structure
      let floorplan = []

      if (this.targetSurvey.responses.floorplan) {
        const rawFloorplan = this.targetSurvey.responses.floorplan

        if (typeof rawFloorplan === 'string') {
          if (rawFloorplan.trim().startsWith('[')) {
            // Handle JSON format
            try {
              const parsed = JSON.parse(rawFloorplan)
              if (Array.isArray(parsed)) {
                // Map to ensure proper IDs
                floorplan = parsed
              }
            } catch (error) {
              console.error('Error parsing floorplan JSON:', error)
              floorplan = []
            }
          }
        }
      }

      console.log('Prepared measurements:', measurements)

      // Return a new object with properly formatted data
      return {
        ...this.targetSurvey.responses,
        measurements: measurements,
        floorplan: floorplan
      }
    }
  },
  async mounted () {
    this.loading = true

    try {
      if (this.$route.query.id) {
        await this.getTargetSurvey({ id: this.$route.query?.id, setState: true })
      }

      // Prepare the responses with measurements properly initialized
      this.preparedResponses = this.prepareMeasurements()

      console.log('preparedResponses:', this.preparedResponses)
    } catch (error) {
      console.error('Error loading survey data:', error)
    } finally {
      this.loading = false
    }
  }
}
</script>
